export const teams = [
  {
    year: 2013,
    title: "The Green Planet",
    host: "Danang, Vietnam",
    image: "/images/2013.webp",
    links: [
      { theme: "Bb9viNLH294" },
      {
        rulebook:
          "https://github.com/rrlgcoeara/robocon-rulebooks/raw/main/rulebook-2013.pdf",
      },
      {
        team: {
          mech: [
            "Chandrashekhar Ghotekar (TE Mech)",
            "Dhananjay Pawar (TE Mech)",
            "Dhiraj Chopade (TE Mech)",
            "Manoj Bhojane (TE Mech)",
            "Tejas Narwade (TE Mech)",
            "Mithun Hatkar (TE Mech)",
            "Rahul Mahajan (TE Mech)",
            "Dhiraj Sahane (TE Mech)",
            "Akshay Patil (TE Mech)",
            "Rahul Nehere (TE Mech)",
            "Hitesh Pant (TE Mech)",
            "Narayan Dekhane (TE Auto)",
            "Rakesh Santapal (TE Auto)",
            "Ashish Sakhare (TE Auto)",
            "Nehal Sayankar (TE Auto)",
            "Shridhar Vader (TE Auto)",

            "Suraj Dongare (SE Mech)",
            "Kapil Mestry (SE Mech)",
            "Prateek Goyal (SE Auto)",
            "Satish Jadhav (SE Civil)",
          ],
          extc: [
            "Akshay Hagawane (TE Instru)",
            "Aniket Nandanwar (TE Comp)",
            "Kishor Deshmukh (TE Comp)",
            "Satyabhama Mate (TE Comp)",
            "Gayatri Borase (TE Comp)",
            "Mohini Gawale (TE Comp)",

            "Hrishikesh Kshirsagar (SE Instru)",
            "Gautam Pratyaksh (SE EnTC)",
            "Pooja Sathe (SE EnTC)",
          ],
        },
      },
      { wiki: "https://en.wikipedia.org/wiki/ABU_Robocon#ABU_Robocon_2013" },
    ],
    leader: "Dhananjay Pawar", //random
    operators: ["Rahul Nehere", "Rushikesh Kshirsagar"], //random
    pitcrew: ["Ashish Sakhare", "Kishor Deshmukh", "Dhiraj Chopde"], //random
    rank: 17
  },
  {
    year: 2014,
    title: "A Salute for Parenthood",
    host: "Pune, India",
    image: "/images/2014.webp",
    links: [
      { theme: "RznC8G-eSB0" },
      {
        rulebook:
          "https://github.com/rrlgcoeara/robocon-rulebooks/raw/main/rulebook-2014.pdf",
      },
      {
        team: {
          mech: [
            "Dhananjay Pawar (BE Mech)",

            "Pranay Hivarkar (TE Mech)",
            "Prateek Goyal (TE Auto)",
            "Ashish Mahale (TE Auto)",
            "Shruti Joshi (TE Auto)",

            "Suraj Waghmare (SE Mech)",
            "Sarthak Jadhav (FE Mech)",
            "Akshay Gaikwad (SE Mech)",
            "Ajinkya Patil (SE Mech)",
            "Sujeet Kashid (SE Mech)",
            "Durgesh Patil (SE Mech)",
            "Dhiraj Gaikar (SE Mech)",
            "Ankit Panchal (SE Mech)",
            "Ashish Mahale (SE Mech)",
            "Diwakar Singh (SE Mech)",
            "Ashutosh Roy (SE Mech)",
            "Sagar Jadhav (SE Mech)",
            "Akshay Kale (SE Mech)",
          ],
          extc: [
            "Rushikesh Kshirsagar (TE Instru)",
            "Arima Shrivastav (TE EnTC)",

            "Hajratali Mulla (SE Instru)",
            "Nadaf Shahrukh (SE Instru)",
            "Sangram Korde (SE Instru)",
            "Shriram Latke (SE Instru)",
            "Akshay Bhange (SE Instru)",
            "Sanjay Shetty (SE Comp)",
            "Utkarsh Kothimbire (SE EnTC)",
            "Vijay Suryawanshi (SE EnTC)",
            "Prajakta Deshmukh (SE EnTC)",
            "Ratnamala Thakare (SE EnTC)",
            "Dnyaneshwar More (SE EnTC)",
            "Shraddha Shinde (SE EnTC)",
            "Shrutika Sanap (SE EnTC)",
            "Pavan Ambhore (SE EnTC)",
            "Rohan Rajmane (SE EnTC)",
            "Ashwini Bhong (SE EnTC)",
          ],
        },
      },
      { wiki: "https://en.wikipedia.org/wiki/ABU_Robocon#ABU_Robocon_2014" },
    ],
    leader: "Dhananjay Pawar", //random
    operators: ["Rushikesh Kshirsagar"], //random
    pitcrew: [
      "Pranay Hivarkar", //random
      "Arima Shrivastav" //random
    ],
    rank: 78
  },
  {
    year: 2015,
    title: "Robominton",
    host: "Yogyakarta, Indonesia",
    image: "/images/2015.webp",
    links: [
      { theme: "4TOIizkzpss" },
      {
        rulebook:
          "https://github.com/rrlgcoeara/robocon-rulebooks/raw/main/rulebook-2015.pdf",
      },
      {
        team: {
          mech: [
            "Durgesh Jadhav (TE Mech)",
            "Suraj Waghmare (TE Mech)",
            "Darshan Chavan (TE Mech)",
            "Ashwini Kadam (TE Mech)",
            "Ajinkya Patil (TE Mech)",
            "Sagar Jadhav (TE Mech)",
            "Ashutosh Roy (TE Mech)",
            "Krishna Pariyar (TE Auto)",

            "Abhijeet Nimbalkar (SE Mech)",
            "Rahul Chavan (SE Mech)",
          ],
          extc: [
            "Rushikesh Kshirsagar (BE Instru)",
            "Pravin Ithape (BE Instru)",

            "Hajratali Mulla (TE Instru)",
            "Aishwarya Khose (TE Instru)",
            "Shriram Latke (TE Instru)",
            "Pragati Khode (TE Instru)",
            "Sangram Korde (TE Instru)",
            "Gauri Gaikwad (TE Instru)",
            "Tejal Bhise (TE Instru)",
            "Swapnali Jadhav (TE Comp)",
            "Sanjay Shetty (TE Comp)",
            "Ratnamala Thakare (TE EnTC)",
            "Seema Shivshetty (TE EnTC)",
            "Shraddha Shinde (TE EnTC)",
            "Anupriya Sharma (TE EnTC)",
            "Namrata Raut (TE EnTC)",
            "Megha Raut (TE EnTC)",

            "Mahesh Dudhal (SE EnTC)",
            "Nilesh Ople (SE EnTC)",
            "Amit Patil (SE Comp)",
          ],
        },
      },
      { wiki: "https://en.wikipedia.org/wiki/ABU_Robocon#ABU_Robocon_2015" },
    ],
    leader: "Rushikesh Kshirsagar", // random
    operators: ["Durgesh Jadhav"], //random
    pitcrew: [
      "Suraj Waghmare", //random
      "Darshan Chavan" //random
    ],
    rank: 22
  },
  {
    year: 2016,
    title: "Clean Energy Recharging the World",
    host: "Bangkok, Thailand",
    image: "/images/2016.webp",
    links: [
      { theme: "nfpg9o-bJ3E" },
      {
        rulebook:
          "https://github.com/rrlgcoeara/robocon-rulebooks/raw/main/rulebook-2016.pdf",
      },
      {
        team: {
          mech: [
            "Nilesh Karmankar (TE Mech)",
            "Sumit Vedpathak (TE Mech)",
            "Prutha Deshmukh (TE Mech)",
            "Tushar Khanre (TE Mech)",
            "Rahul Chavan (TE Mech)",
            "Vijay Bankar (TE Mech)",

            "Hrishikesh Suryawanshi (SE Mech)",
            "Vaishnavi Thigale (SE Mech)",
            "Akshata Manjare (SE Mech)",
            "Snehal Sarode (SE Mech)",
            "Mohini Ghode (SE Mech)",
            "Rajat Yadav (SE Mech)",

            "Kaustubh Gunjal (FE Auto)",
            "Saurabh Gunjal (FE Auto)",
            "Mohan Panday (FE Auto)",
            "Aniket Bunde (FE Auto)",
            "Jay Wanjare (FE Auto)",
          ],
          extc: [
            "Pranav Sansuddi (TE EnTC)",
            "Pravin Kakade (TE EnTC)",
            "Shaikh Sammer (TE EnTC)",
            "Sharim Naveed (TE EnTC)",
            "Mahesh Dhudhal (TE EnTC)",

            "Rohit Kumar Singh (SE Comp)",

            "Hiteshee Rathod (FE Instru)",
            "Nilesh Ople (FE EnTC)",
          ],
        },
      },
      { wiki: "https://en.wikipedia.org/wiki/ABU_Robocon#ABU_Robocon_2016" },
    ],
    leader: "Sagar Jadhav",
    operators: ["Rahul Chavan"],
    pitcrew: ["Akash Tayade", "Nilesh Karmankar", "Sameer Sheikh"],
    rank: 5,
    achievement: "National Rookie Award",
  },
  {
    year: 2017,
    title: "Asobi: The landing disc",
    host: "Tokyo, Japan",
    image: "/images/2017.webp",
    links: [
      { theme: "V9rYLZiT1Ow" },
      {
        rulebook:
          "https://github.com/rrlgcoeara/robocon-rulebooks/raw/main/rulebook-2017.pdf",
      },
      {
        team: {
          mech: [
            "Nilesh Karmankar (BE Mech)",
            "Sumit Vedpathak (BE Mech)",
            "Prutha Deshmukh (BE Mech)",
            "Rahul Chavan (BE Mech)",
            "Akash Tayade (BE Mech)",
            "Tushar Khanre (BE Mech",
            "Vijay Bankar (BE Mech)",

            "Akshata Manjare (TE Mech)",
            "Snehal Sarode (TE Mech)",
            "Mohini Ghode (TE Mech)",
            "Rajat Yadav (TE Mech)",

            "Shubham Vadnere (SE Mech)",
            "Rutuja Raut (SE Mech)",
            "Suhas Raut (SE Mech)",
            "Kaustubh Gunjal (SE Auto)",
            "Saurabh Gunjal (SE Auto)",
            "Aniket Bunde (SE Auto)",
            "Mohan Panday (SE Auto)",
            "Jay Wanjare (SE Auto)",

            "Sagar Wadhai (FE Mech)",
          ],
          extc: [
            "Pravin Kakade (BE EnTC)",
            "Sharim Naveed (BE EnTC)",
            "Sameer Shaikh (BE EnTC)",

            "Sushmita Abhivant (TE Comp)",
            "Rohit Kumar Singh (TE Comp)",
            "Sanket Sanklecha (TE Comp)",

            "Gitanjali Sable (SE Instru)",
            "Rahul Shete (SE Instru)",
            "Hiteshee Rathod (SE Comp)",

            "Vaishnavi Upasani (FE Instru)",
            "Tejas Chaudhari (FE Instru)",
            "Rohit Bangal (FE Instru)",
            "Rakshanda Meshram (FE Comp)",
            "Sanjana Jadhav (FE Comp)",
            "Priya Unde (FE Comp)",
            "Kajal Bhamare (FE EnTC)",
          ],
        },
      },
      { wiki: "https://en.wikipedia.org/wiki/ABU_Robocon#ABU_Robocon_2017" },
    ],
    leader: "Rahul Chavan", //random
    operators: ["Rahul Chavan"],//random
    pitcrew: [
      "Nilesh Karmankar", //random
      "Sumit Vedpathak", //random
      "Pravin Kakade" //random
    ],
    rank: 7
  },
  {
    year: 2018,
    title: "Throwing Shuttlecock",
    host: "Ninh Binh, Vietnam",
    image: "/images/2018.webp",
    links: [
      { theme: "ohfiGCVF6D8" },
      {
        rulebook:
          "https://github.com/rrlgcoeara/robocon-rulebooks/raw/main/rulebook-2018.pdf",
      },
      {
        team: {
          mech: [
            "Rutuja Raut (TE Mech)",
            "Kaustubh Gunjal (TE Auto)",
            "Saurabh Gunjal (TE Auto)",
            "Aniket Bunde (TE Auto)",
            "Sidhhant Sananse (TE Auto)",

            "Sagar Wadhai (SE Mech)",
            "Prakash Shinde (SE Mech)",
            "Shubham Patil (SE Mech)",
            "Akash Lahane (SE Mech)",
            "Jyoti Khare (SE Mech)",

            "Rohini Kulkarni (FE Mech)",
            "Ankit Pareek (FE Mech)",
            "Kunal Patil (FE Auto)",
            "Shreyash Patil (FE Auto)",
          ],
          extc: [
            "Akshay Tawar (TE Instru)",
            "Rahul Shete (TE Instru)",
            "Hiteshee Rathod (TE Comp)",
            "Sharad Jadhav (TE EnTC)",

            "Tejas Chaudhari (SE Instru)",
            "Rohit Bangal (SE Instru)",
            "Kajal Bhamare (SE EnTC)",
            "Sanjana Jadhav (SE Comp)",
            "Priya Unde (SE Comp)",
            "Mayank Jha (SE Instru)",
            "Kunal Pawar (SE Instru)",
            "Unnati Kulkarni (SE EnTC)",
            "Chaitanya Ajabe (SE EnTC)",
            "Akash Gudhade (SE Comp)",
            "Hemant Ghuge (SE EnTC)",
            "Abhishek Wadmare (FE Comp)",
            "Abhijeet Gite (FE Instru)",
            "Ganesh Chincholkar (FE Comp)",
            "Pratik Adik (FE Instru)",
            "Arwind Badwar (SE Comp)",
          ],
        },
      },
      { wiki: "https://en.wikipedia.org/wiki/ABU_Robocon#ABU_Robocon_2018" },
    ],
    leader: "Kaustubh Gunjal",
    operators: ["Saurabh Gunjal"],
    pitcrew: ["Siddhant Sananse", "Rohit Bangal"],
    rank: 2,
    achievement: "Smart and Simple Robot",
  },
  {
    year: 2019,
    title: "Sharing the knowledge",
    host: "Ulaanbaatar, Mongolia",
    image: "/images/2019.webp",
    links: [
      { theme: "p_xvhrg6LRA" },
      {
        rulebook:
          "https://github.com/rrlgcoeara/robocon-rulebooks/raw/main/rulebook-2019.pdf",
      },
      {
        team: {
          mech: [
            "Kaustubh Gunjal (BE Auto)",
            "Saurabh Gunjal (BE Auto)",
            "Ankit Bunde (BE Auto)",

            "Sagar Wadhai (TE Mech)",
            "Prakash Shinde (TE Mech)",
            "Ankit Ingale (TE Mech)",

            "Prathmesh Nalawade (SE Mech)",
            "Pranjal Maware (SE Mech)",
            "Varsha Hanbar (SE Mech)",
            "Nikhil Jadhav (SE Mech)",
            "Kunal Pawar (SE Mech)",
            "Kunal Patil (SE Auto)",

            "Harshwardhan Lokhande (FE Mech)",
            "Aniruddha Wadekar (FE Mech)",
            "Somesh Desai (FE Mech)",
            "Kunal Khairnar (FE Auto)",
            "Ajay Jawalkar (FE Auto)",
            "Swapnil Pardhi (FE Auto)",
          ],
          extc: [
            "Rahul Shete (BE Instru)",
            "Hiteshee Rathod (BE Comp)",
            "Sharad Jadhav (BE EnTC)",

            "Rohit Bangal (TE Instru)",
            "Tejas Chaudhari (TE Instru)",
            "Kunal Pawar (Te Instru)",
            "Sanjana Jadhav (TE Comp)",
            "Revati Kulkarni (TE EnTC)",
            "Unnati Kulkarni (TE EnTC)",
            "Hemant Ghuge (TE EnTC)",
            "Aadesh Borate (TE EnTC)",

            "Parasram falake (SE Instru)",
            "Nikhil Sadafule (SE Instru)",
            "Abhijeet Gite (SE Instru)",
            "Ganesh Chincholkar (SE Comp)",
            "Gautam Helange (SE Comp)",
            "Shubham Shinde (SE Comp)",

            "Satyajeet Nalawade (FE Instru)",
            "Madhukar Gavani (FE Comp)",
            "Dhananjay Tanpure (Fe Comp)",
            "Pooja Mulik (FE EnTC)",
          ],
        },
      },
      { wiki: "https://en.wikipedia.org/wiki/ABU_Robocon#ABU_Robocon_2019" },
    ],
    leader: "Rohit Bangal",
    operators: ["Sagar Wadhai"],
    pitcrew: ["Ganesh Chincholkar", "Prathmesh Nalawade"],
    rank: 4,
    achievement: "Judges Special Award",
  },
  {
    year: 2020,
    title: "ROBO RUGBY 7s",
    host: "Suva, Fiji",
    image: "/images/2020.webp",
    links: [
      { theme: "-G3lERSXyp8" },
      {
        rulebook:
          "https://github.com/rrlgcoeara/robocon-rulebooks/raw/main/rulebook-2020.pdf",
      },
      {
        team: {
          mech: [
            "Sagar Wadhai (BE Mech)",

            "Prathmesh Nalawade (TE Mech)",
            "Pranjal Maware (TE Mech)",
            "Varsha Hanbar (TE Mech)",
            "Kunal Patil (TE Auto)",

            "Harshwardhan Lokhande (SE Mech)",
            "Somesh Desai (SE Mech)",
            "Swapnil Pardhi (SE Mech)",
            "Aniruddha Wadekar (SE Mech)",
            "Kunal Khairnar (SE Auto)",

            "Aditi Lohar (FE Mech)",
            "Aniket Tayade (FE Mech)",
            "Mrunal Chavan (FE Mech)",
            "Prajakta Khairmode (FE Mech)",
            "Mandar Dalimbe (FE Auto)",
            "Atharva Toradmal (FE Auto)",
            "Akash Khure (FE Auto)",
          ],
          extc: [
            "Rohit Bangal (BE Instru)",
            "Tejas Chaudhari (BE Instru)",
            "Kunal Pawar (BE Instru)",
            "Aadesh Borate (BE EnTC)",
            "Hemant Ghuge (BE EnTC)",
            "Unnati Kulkarni (BE EnTC)",
            "Revati Kulkarni (BE EnTC)",

            "Shubham Shinde (TE Comp)",

            "Satyajeet Nalawade (SE Instru)",
            "Madhukar Gavani (SE Comp)",
            "Dhananjay Tanpure (SE Comp)",
            "Utkarsh Pawar (SE Comp)",
            "Dnyaneshwar Shinde (SE Comp)",
            "Akshay Darade (SE Comp)",
            "Pritam Landage (SE EnTC)",
            "Subhash Mangale (SE EnTC)",

            "Madhura Hande-Deshmukh (FE Instru)",
            "Sushant Phalke (FE Comp)",
            "Shubham Singh (FE Comp)",
            "Akash Ghadge (FE Comp)",
            "Swarangi Thakare (FE EnTC)",
            "Shubham Kothawade (FE EnTC)",
            "Shantanu Paturkar (FE EnTC)",
          ],
        },
      },
      { wiki: "https://en.wikipedia.org/wiki/ABU_Robocon#ABU_Robocon_2020" },
    ],
    leader: "Prathmesh Nalawade",
    operators: ["Kunal Patil", "Kunal Khairnar"],
    pitcrew: ["Satyajeet Nalawade", "Harshwardhan Lokhande", "Swapnil Pardhi"],
    rank: 14,
  },
  {
    //2021
    year: 2021,
    title: "Throwing Arrows into Pots",
    host: "Jimo, China",
    image: "/images/2021.webp",
    links: [
      { theme: "R3p4VtIQhu4" },
      {
        rulebook:
          "https://github.com/rrlgcoeara/robocon-rulebooks/raw/main/rulebook-2021.pdf",
      },
      {
        team: {
          mech: [
            "Prathmesh Nalawade (BE Mech)",
            "Pranjal Maware (BE Mech)",
            "Varsha Hanbar (BE Mech)",
            "Kunal Patil (BE Auto)",

            "Harshwardhan Lokhande (TE Mech)",
            "Swapnil Pardhi (TE Mech)",
            "Kunal Khairnar (TE Auto)",

            "Aditi Lohar (SE Mech)",
            "Mrunal Chavan (SE Mech)",
            "Prajakta Khairmode (SE Mech)",
            "Mandar Dalimbe (SE Auto)",
            "Atharva Toradmal (SE Auto)",
          ],
          extc: [
            "Shubham Shinde (BE Comp)",

            "Satyajeet Nalawade (TE Instru)",
            "Madhukar Gavani (TE Comp)",
            "Dhananjay Tanpure (TE Comp)",
            "Dnyaneshwar Shinde (TE Comp)",
            "Pritam Landage (TE EnTC)",
            "Subhash Mangale (TE EnTC)",

            "Akash Khure (SE Instru)",
            "Madhura Hande-Deshmukh (SE Instru)",
            "Sushant Phalke (SE Comp)",
            "Shubham Singh (SE Comp)",
            "Swarangi Thakare (SE EnTC)",
          ],
        },
      },
      { wiki: "https://en.wikipedia.org/wiki/ABU_Robocon#ABU_Robocon_2021" },
    ],
    leader: "Prathmesh Nalawade",
    operators: ["Kunal Khairnar", "Mandar Dalimbe"],
    pitcrew: ["Madhukar Gavani", "Dhananjay Tanpure", "Swarangi Thakare"],
    rank: "-",
  },
];
