import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ThemeProvider, createMuiTheme } from "@material-ui/core";
import Home from "./components/pages/Home";
import Contact from "./components/pages/Contact";
import About from "./components/pages/About";
import Team from "./components/pages/Team";
import ErrorPage from "./components/pages/ErrorPage";
import { PageTopOnRouteChange } from "./components/common/ui";

import "./styles/home.scss";
import "./styles/layout.scss";
import "./styles/about.scss";
import "./styles/errorpage.scss";
import "./styles/cards.scss";
import "./styles/contact.scss";
import "./styles/team.scss";
import "./styles/robocon.scss";

import { teams } from "./config/teams-data";
import RoboconPage from "./components/pages/Robocon";

const theme = createMuiTheme({
  palette: {
    primary: {
      // main: '#030016',
      main: "#07162e",
    },
    secondary: {
      main: "#e1a87a",
    },
    success: {
      main: "#51b651",
    },
    error: {
      main: "#d96565",
    },
    type: "light",
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <div className="App">
          <PageTopOnRouteChange>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/contact" component={Contact} />
              <Route exact path="/about" component={About} />
              {teams.map((team) => (
                <Route
                  exact
                  key={team.year}
                  path={`/robocon/${team.year}`}
                  render={() => <Team {...team} />}
                />
              ))}
              <Route exact path="/robocon" component={RoboconPage} />
              <Route component={ErrorPage} />
            </Switch>
          </PageTopOnRouteChange>
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;
