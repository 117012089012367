export const achievementData = [
  {
    title: "JUDGES SPECIAL AWARD",
    year: "2019",
    teamLeader: "Rohit Bangal",
    operator: "Sagar Wadhai",
    pitCrews: ["Ganesh Chincholkar", "Prathamesh Nalawade"],
    image: "/images/2019.webp",
  },
  {
    title: "SMART AND SIMPLE ROBOT",
    year: "2018",
    teamLeader: "Kaustubh Gunjal",
    operator: "Saurabh Gunjal",
    pitCrews: ["Siddhant Sananse", "Rohit Bangal"],
    image: "/images/2018.webp",
  },
  {
    title: "NATIONAL ROOKIE AWARD",
    year: "2016",
    teamLeader: "Sagar Jadhav",
    operator: "Rahul Chavan",
    pitCrews: ["Akash Tayade", "Nilesh karmankar", "Sameer Sheikh"],
    image: "/images/2016.webp",
  },
];
