import React from "react";
import Layout from "../../layout";
import ContactPage from "./ContactPage";

const ContactPageIndex = () => {
  return (
    <Layout>
      <ContactPage />
    </Layout>
  );
};

export default ContactPageIndex;
