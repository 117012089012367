import React from "react";
import Layout from "../../layout";
import Navigator from "../Team/Navigator";
import RoboconAbout from "./RoboconAbout";

const RoboconPage = (props) => {
  return (
    <Layout>
      <div className="team-page-wrapper">
        {/* Here 2021 refers to the last year we want to display on website */}
        <Navigator robocon year={2021}>
          <RoboconAbout />
        </Navigator>
      </div>
    </Layout>
  );
};

export default RoboconPage;
