import React from "react";

const RoboconAbout = () => {
  return (
    <div className="robocon-wrapper">
      <div className="robocon-header">
        <center>
          <div>ABU Asia-Pacific Robot Contest</div>
        </center>
      </div>
      <br />
      <div className="robocon-content">
        <img
          src="https://www.abu.org.my/images/xtopia_asset/Event/PMPC-2015/ABU%20Logo_with%20_words.jpg"
          alt="ABU Logo"
        />
        <br />
        The ABU Asia-Pacific Robot Contest(<strong>ABU Robocon</strong>) is an
        Asian-Oceanian college robot competition, founded in{" "}
        <strong>2002</strong> by{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.abu.org.my/"
        >
          Asia-Pacific Broadcasting Union
        </a>
        . The contest aims to create friendship among young people with similar
        interests who will lead their countries in the 21st century, as well as
        help advance engineering and broadcasting technologies in the region.
      </div>
      <div className="links">
        <a
          href="https://en.wikipedia.org/wiki/ABU_Robocon"
          target="_blank"
          rel="noopener noreferrer"
        >
          ABU Robocon
        </a>
        <a
          href="https://en.wikipedia.org/wiki/Robocon_India"
          target="_blank"
          rel="noopener noreferrer"
        >
          Robocon India
        </a>
      </div>
    </div>
  );
};

export default RoboconAbout;
