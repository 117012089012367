import React from "react";
import { NavLink } from "react-router-dom";
import { FaAngleRight, FaAngleLeft } from "react-icons/fa";

const Navigator = (props) => {
  return (
    <div className="Navigator">
      {props.robocon || [
        <div className="right">
          {props.year === 2013
            ? null
            : [
              <NavLink key={props.year - 1} to={`/robocon/${props.year - 1}`}>
                <FaAngleRight size="3em" />
              </NavLink>,
              <h4>{props.year - 1}</h4>,
            ]}
        </div>,
        <div>{props.children}</div>,
        <div className="left">
          {/* Here 2021 refers to the last year we want to display on website */}
          {props.year === 2021
            ? [
              <NavLink key="robocon" to={"/robocon"}>
                <FaAngleLeft size="3em" />
              </NavLink>,
              <h4>Robocon</h4>,
            ]
            : [
              <NavLink key={props.year + 1} to={`/robocon/${props.year + 1}`}>
                <FaAngleLeft size="3em" />
              </NavLink>,
              <h4>{props.year + 1}</h4>,
            ]}
        </div>,
      ]}
      {props.robocon && [
        <div className="right">
          <NavLink key={props.year} to={`/robocon/${props.year}`}>
            <FaAngleRight size="3em" />
          </NavLink>
          <h4>{props.year}</h4>
        </div>,
        <div>{props.children}</div>,
      ]}
    </div>
  );
};

export default Navigator;
