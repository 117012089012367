import React from "react";

const ThemeModal = (props) => {
  return (
    <div className="theme-modal">
      <iframe
        title={props.title}
        src={`https://www.youtube.com/embed/${props.link}?autoplay=0&mute=1`}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        className="video-embed"
      />
    </div>
  );
};

export default ThemeModal;
