import React from "react";
import Layout from "../../layout";
import TopSection from "./TopSection";
import HomeAbout from "./HomeAbout";
import Achievements from "./Achievements";

const HomePage = () => {
  return (
    <Layout home>
      <div className="home-wrapper">
        <TopSection />
        <HomeAbout />
        <Achievements />
      </div>
    </Layout>
  );
};

export default HomePage;
