export const firebaseConfig = {
  apiKey: "AIzaSyDXfCF8SAv4Kkri2ADj39ngoc3l5ZGYu0M",
  authDomain: "rrl-gcoeara.firebaseapp.com",
  databaseURL: "https://rrl-gcoeara.firebaseio.com",
  projectId: "rrl-gcoeara",
  storageBucket: "rrl-gcoeara.appspot.com",
  messagingSenderId: "92707669182",
  appId: "1:92707669182:web:d465b3afb80f4f92920aef",
  measurementId: "G-4WF7B2L2C9",
};
