import React from "react";

const TeamModal = (props) => {
  console.log(props);
  return (
    <div className="team-modal">
      <div>
        <strong>Mechanical</strong>
        <hr />
        {props.teamlist.mech.map((member) => {
          return <div className="member">{member}</div>;
        })}
      </div>
      <div>
        <strong>EXTC</strong>
        <hr />
        {props.teamlist.extc.map((member) => {
          return <div className="member">{member}</div>;
        })}
      </div>
    </div>
  );
};

export default TeamModal;
