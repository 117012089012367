import React from "react";

const Footer = () => {
  return (
    <div className="footer-wrapper">
      <div className="footer-content">
        <p className="copy">
          © {new Date().getFullYear()} Robotics Research Lab.
        </p>
        <p className="copy">All rights reserved.</p>
      </div>
    </div>
  );
};

export default Footer;
