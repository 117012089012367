import React from "react";
import Layout from "../../layout";
import Navigator from "./Navigator";
import Team from "./Team";

const TeamPage = (props) => {
  return (
    <Layout>
      <div className="team-page-wrapper">
        <Navigator year={props.year}>
          <Team {...props} />
        </Navigator>
      </div>
    </Layout>
  );
};

export default TeamPage;
