import {
  AppBar,
  Dialog,
  IconButton,
  makeStyles,
  Slide,
  Toolbar,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import {
  FaChevronDown,
  FaFilePdf,
  FaImages,
  FaUserFriends,
  FaWikipediaW,
  FaYoutube,
} from "react-icons/fa";
import RulebookModal from "./RulebookModal";
import TeamModal from "./TeamModal";
import ThemeModal from "./ThemeModal";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "sticky",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Team = (props) => {
  console.log(props);

  const [modal, setModal] = useState(false);
  const [modalContent, setModalContent] = useState("none");

  const classes = useStyles();

  const handleModal = (state, content) => {
    setModalContent(content);
    setModal(state);
  };

  const getLink = (link) => {
    console.log(link);
    const key = Object.keys(link)[0];
    switch (key) {
      case "theme":
        // eslint-disable-next-line no-unused-expressions
        return (
          <div onClick={() => handleModal(true, "theme")}>
            <FaYoutube color="red" size="2.5em" />
            <br />
            <strong>Video</strong>
          </div>
        );
      case "rulebook":
        return (
          <div onClick={() => handleModal(true, "rulebook")}>
            <FaFilePdf color="rgb(230, 31, 17)" size="2.5em" />
            <br />
            <strong>Rulebook</strong>
          </div>
        );
      case "wiki":
        return (
          <div
            onClick={(event) => {
              event.preventDefault();
              handleModal(true, "wiki");
            }}
          >
            <a href={link.wiki} target="_blank" rel="noopener noreferrer">
              <FaWikipediaW size="2.5em" />
              <br />
              <strong>Wiki</strong>
            </a>
          </div>
        );
      case "gallery":
        return (
          <div>
            <FaImages color="rgb(10, 27, 39)" size="2.5em" />
            <br />
            <strong>Gallery</strong>
          </div>
        );
      case "team":
        return (
          <div onClick={() => handleModal(true, "team")}>
            <FaUserFriends color="green" size="2.5em" />
            <br />
            <strong>Team</strong>
          </div>
        );
      default:
        break;
    }
  };

  const getModelTitle = () => {
    switch (modalContent) {
      case "theme":
        return `Robocon ${props.year} Theme Video`;
      case "rulebook":
        return `Robocon ${props.year} Rulebook`;
      case "team":
        return `Team Abhedya ${props.year}`;
      case "wiki":
        return `Robocon ${props.year}`;
      default:
        break;
    }
  };
  const getModalComponent = () => {
    switch (modalContent) {
      case "theme":
        return (
          <ThemeModal
            link={props.links[0][Object.keys(props.links[0])[0]]}
            title={`ABU Robocon ${props.year}, ${props.host}`}
          />
        );
      case "rulebook":
        return (
          <RulebookModal
            title={`Robocon ${props.year} Rulebook`}
            pdfUrl={props.links[1].rulebook}
          />
        );
      case "team":
        return <TeamModal teamlist={props.links[2].team} />;
      case "wiki":
        return (
          <div className="wiki-modal">
            <iframe
              src={props.links[3].wiki}
              frameborder="0"
              title="wiki"
            ></iframe>
          </div>
        );
      default:
        break;
    }
  };

  return (
    <div className="team">
      <Dialog
        fullScreen
        open={modal}
        TransitionComponent={Transition}
        onClose={() => {
          handleModal(false, "none");
        }}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                handleModal(false, "none");
              }}
              aria-label="close"
            >
              <FaChevronDown size="1.5em" color="red" />
            </IconButton>
            <Typography align="center" variant="h6" className={classes.title}>
              {getModelTitle()}
            </Typography>
          </Toolbar>
        </AppBar>
        <div className="modal-content">{getModalComponent()}</div>
      </Dialog>
      <div className="team-top">
        <div className="image">
          <img src={props.image} alt={`Team ${props.year}`} />
        </div>
        <div className="details">
          {props.title && <div className="details-title">{props.title}</div>}
          {props.year && (
            <div className="details-year">
              {props.year} :- {props.host}
            </div>
          )}
          {props.leader && (
            <div className="details-leader">
              <strong>Team Leader</strong>
              <br />
              {props.leader}
            </div>
          )}
          {props.operators && (
            <div className="details-operator">
              <strong>Operator</strong>
              <br />
              {props.operators.join(", ")}
            </div>
          )}
          {props.pitcrew && (
            <div className="details-pit-crews">
              <strong>Pit Crews</strong>
              <br />
              {props.pitcrew.join(", ")}
            </div>
          )}
          {props.rank && (
            <div className="details-rank">
              <strong>Rank</strong>
              <br />
              {props.rank}
              <br />
              {props.achievement && props.achievement}
            </div>
          )}
        </div>
      </div>
      {props.links && (
        <div className="team-links">
          {props.links.map((link) => {
            return (
              <div className="link" key={Object.keys(link)[0]}>
                {getLink(link)}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Team;
