import React from "react";

const RulebookModal = (props) => {
  return (
    <div className="rulebook-modal">
      <iframe
        title={props.title}
        src={`https://docs.google.com/viewerng/viewer?url=${props.pdfUrl}&embedded=true`}
        frameBorder="0"
        height="100%"
        width="100%"
      ></iframe>
    </div>
  );
};

export default RulebookModal;
